import {
  AppConfirm,
  AppPaginator,
  BackLink,
  BackLinkListItem,
  BacklinkFilter,
  BacklinkFormData,
  ColumnDefinitionType,
  DomainViewModel,
  PageChangeEvent,
  SearchParam,
  useDialog,
} from '@backlinkit/shared';
import useBacklinks from '../../hooks/useBacklinks';
import AppBacklinksTable from '../../components/app/app-backlinks-table-component/backlink-performance-table';

type BacklinkBatchLinksProps = {
  batchId: string;
  domain: DomainViewModel;
  refetchBatches: () => void;
};

const BacklinkBatchLinks: React.FC<BacklinkBatchLinksProps> = ({
  batchId,
  domain,
  refetchBatches,
}) => {
  const dialog = useDialog();
  const {
    domainBacklinks,
    tempBacklinks,
    tempGoogleData,
    menuItems,
    addBacklink,
    editBacklink,
    handleSearchChange,
    handleSortByColumnClick,
    handleFiltersChange,
    handleBacklinksRescrape,
    handleBacklinkGoogleRescrapeProcess,
    handleBackButton,
    refreshBacklinks,
    pagination,
    handleOnPageChange,
    handleBacklinkExternalReport,
    handleDeleteBacklinks,
  } = useBacklinks({ filter: { backlinkBatchId: batchId } });

  const overideFilterChange = (filter: BacklinkFilter) => {
    const filterOverride: BacklinkFilter = { ...filter, backlinkBatchId: batchId };
    handleFiltersChange(filterOverride);
  };

  const handleAddLinks = async (
    backlinkFormsData: BacklinkFormData[],
    currentDomain: DomainViewModel,
    batchPrice?: number
  ) => {
    await addBacklink(backlinkFormsData, currentDomain, batchPrice, batchId);
    refetchBatches();
  };

  const handleEditLink = async (
    currentBacklink: BackLink,
    backlinkForm: BacklinkFormData,
    currentDomain: DomainViewModel,
    isNoteEdit?: boolean | undefined,
    isPriceEdit?: boolean | undefined
  ) => {
    await editBacklink(currentBacklink, backlinkForm, currentDomain, isNoteEdit, isPriceEdit);
    refetchBatches();
  };

  const handleRefreshBacklinks = async () => {
    refreshBacklinks();
    refetchBatches();
  };

  const handleDeleteLinks = (selectedBacklinks: BackLinkListItem[]) => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selectedBacklinks.length} selected Backlinks?`}
            onConfirm={async () => {
              onSubmit();

              await handleDeleteBacklinks(selectedBacklinks);
              refetchBatches();
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  return (
    // <BacklinksTable
    //   currentDomain={domain}
    //   title={title}
    //   parentUrl={domain?.url || ''}
    //   data={domainBacklinks?.data || []}
    //   tempBacklinkCount={tempBacklinks && tempBacklinks.length}
    //   tempGoogleDataCount={tempGoogleData && tempGoogleData.length}
    //   additionalBacklinkColumns={additionalBackLinkColumns}
    //   additionalMenuItems={menuItems}
    //   isBatchCreateDisabled={false}
    //   onAddClick={(
    //     backlinkFormsData: BacklinkFormData[],
    //     currentDomain: DomainListItem,
    //     batchPrice?: number | undefined
    //   ) => handleAddLinks(backlinkFormsData, currentDomain, batchPrice)}
    //   onEditBacklink={(
    //     backlinkForm: BacklinkFormData,
    //     currentDomain: DomainListItem,
    //     isNoteEdit?: boolean | undefined,
    //     isPriceEdit?: boolean | undefined
    //   ) => handleEditLink(backlinkForm, currentDomain, isNoteEdit, isPriceEdit)}
    //   onSearchChange={(searchParams: SearchParam) => handleSearchChange(searchParams)}
    //   onSortByColumnClick={(columns: ColumnDefinitionType<BackLinkListItem>[]) =>
    //     handleSortByColumnClick(columns)
    //   }
    //   onFiltersChange={(params: BacklinkFilter) => overideFilterChange(params)}
    //   onSelectedBacklinksRescrape={(selection: BackLinkListItem[]) => {
    //     handleBacklinksRescrape(selection);
    //     refetchBatches();
    //   }}
    //   onSelectedBacklinksGoogleRescrape={(selection: BackLinkListItem[]) =>
    //     handleBacklinkGoogleRescrapeProcess(selection)
    //   }
    //   onSelectedBacklinksDelete={(selection: BackLinkListItem[]) => handleDeleteLinks(selection)}
    //   onSelectedBacklinksExternalReport={(selection: BackLinkListItem[]) =>
    //     handleBacklinkExternalReport(selection)
    //   }
    //   onBackButtonClick={() => handleBackButton()}
    //   refetch={() => handleRefreshBacklinks()}
    //   batchId={batchId}
    // >
    //   <AppPaginator
    //     pagination={pagination}
    //     onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
    //   ></AppPaginator>
    // </BacklinksTable>

    <AppBacklinksTable
      currentDomain={domain as DomainViewModel}
      title={'Batch Links'}
      parentUrl={domain?.url || ''}
      data={domainBacklinks?.data || []}
      tempBacklinkCount={tempBacklinks && tempBacklinks.length}
      tempGoogleDataCount={tempGoogleData && tempGoogleData.length}
      additionalBacklinkColumns={[]}
      additionalMenuItems={menuItems}
      isBatchCreateDisabled={false}
      onAddClick={(
        backlinkFormsData: BacklinkFormData[],
        currentDomain: DomainViewModel,
        batchPrice?: number | undefined
      ) => handleAddLinks(backlinkFormsData, currentDomain, batchPrice)}
      onEditBacklink={(
        currentBacklink: BackLink,
        backlinkForm: BacklinkFormData,
        currentDomain: DomainViewModel,
        isNoteEdit?: boolean | undefined,
        isPriceEdit?: boolean | undefined
      ) => handleEditLink(currentBacklink, backlinkForm, currentDomain, isNoteEdit, isPriceEdit)}
      onSearchChange={(searchParams: SearchParam) => handleSearchChange(searchParams)}
      onSortByColumnClick={(columns: ColumnDefinitionType<BackLinkListItem>[]) =>
        handleSortByColumnClick(columns)
      }
      onFiltersChange={(params: BacklinkFilter) => overideFilterChange(params)}
      onSelectedBacklinksRescrape={(selection: BackLinkListItem[]) =>
        handleBacklinksRescrape(selection)
      }
      onSelectedBacklinksDelete={(selection: BackLinkListItem[]) => handleDeleteLinks(selection)}
      onSelectedBacklinksExternalReport={(selection: BackLinkListItem[]) =>
        handleBacklinkExternalReport(selection)
      }
      onSelectedBacklinksGoogleRescrape={(selection: BackLinkListItem[]) =>
        handleBacklinkGoogleRescrapeProcess(selection)
      }
      onBackButtonClick={() => handleBackButton()}
      refetch={() => handleRefreshBacklinks()}
      batchId={batchId}
    >
      <AppPaginator
        pagination={pagination}
        onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
      ></AppPaginator>
    </AppBacklinksTable>
  );
};

export default BacklinkBatchLinks;
