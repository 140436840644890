import * as React from 'react';
import { Flex } from '@chakra-ui/react';
import DomainLayout from '../../components/layouts/domain-layout';
import {
  AppPaginator,
  BackLink,
  BacklinkFilter,
  BacklinkFormData,
  BackLinkListItem,
  ColumnDefinitionType,
  DomainListItem,
  DomainViewModel,
  PageChangeEvent,
  SearchParam,
} from '@backlinkit/shared';
import useBacklinks from '../../hooks/useBacklinks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { selectDomain } from '../../store/slices/domain.slice';
import { useAppSelector } from '../../store/store';
import BaseLayout from '../../components/layouts/base-layout';
import AppBacklinksTable from '../../components/app/app-backlinks-table-component/backlink-performance-table';

export const TestPage: React.FC = () => {
  const { state } = useLocation();
  const domain = useAppSelector(selectDomain);
  const {
    domainBacklinks,
    tempBacklinks,
    tempGoogleData,
    menuItems,
    addBacklink,
    editBacklink,
    handleSearchChange,
    handleSortByColumnClick,
    handleFiltersChange,
    handleBacklinksRescrape,
    handleBackButton,
    refreshBacklinks,
    pagination,
    handleOnPageChange,
    handleBacklinkExternalReport,
    handleBacklinkGoogleRescrapeProcess,
    handleDeleteBacklinkClicked,
    actionSetupBacklinks,
  } = useBacklinks({ filter: { hasErrors: state && state.hasErrors ? true : undefined } });

  useEffect(() => {
    if (domain) {
      actionSetupBacklinks({
        hasErrors: state && state?.hasErrors,
      });
    }
  }, [domain]);

  const handleAddLinks = async (
    backlinkFormsData: BacklinkFormData[],
    currentDomain: DomainViewModel,
    batchPrice?: number,
    batch?: string | undefined
  ) => {
    await addBacklink(backlinkFormsData, currentDomain, batchPrice, batch);
  };

  const handleEditLinks = async (
    currentBacklink: BackLink,
    backlinkForm: BacklinkFormData,
    currentDomain: DomainViewModel,
    isNoteEdit?: boolean | undefined,
    isPriceEdit?: boolean | undefined
  ) => {
    await editBacklink(currentBacklink, backlinkForm, currentDomain, isNoteEdit, isPriceEdit);
  };

  return (
    <BaseLayout>
      <DomainLayout>
        <Flex px={6} flex={1} direction="column" width={'100%'}>
          <AppBacklinksTable
            currentDomain={domain as DomainViewModel}
            title={'POC Table'}
            parentUrl={domain?.url || ''}
            data={domainBacklinks?.data || []}
            tempBacklinkCount={tempBacklinks && tempBacklinks.length}
            tempGoogleDataCount={tempGoogleData && tempGoogleData.length}
            additionalBacklinkColumns={[]}
            additionalMenuItems={menuItems}
            isBatchCreateDisabled={false}
            onAddClick={(
              backlinkFormsData: BacklinkFormData[],
              currentDomain: DomainViewModel,
              batchPrice?: number | undefined,
              batch?: string | undefined
            ) => handleAddLinks(backlinkFormsData, currentDomain, batchPrice, batch)}
            onEditBacklink={(
              currentBacklink: BackLink,
              backlinkForm: BacklinkFormData,
              currentDomain: DomainViewModel,
              isNoteEdit?: boolean | undefined,
              isPriceEdit?: boolean | undefined
            ) =>
              handleEditLinks(currentBacklink, backlinkForm, currentDomain, isNoteEdit, isPriceEdit)
            }
            onSearchChange={(searchParams: SearchParam) => handleSearchChange(searchParams)}
            onSortByColumnClick={(columns: ColumnDefinitionType<BackLinkListItem>[]) =>
              handleSortByColumnClick(columns)
            }
            onFiltersChange={(params: BacklinkFilter) => handleFiltersChange(params)}
            onSelectedBacklinksRescrape={(selection: BackLinkListItem[]) =>
              handleBacklinksRescrape(selection)
            }
            onSelectedBacklinksDelete={(selection: BackLinkListItem[]) =>
              handleDeleteBacklinkClicked(selection)
            }
            onSelectedBacklinksExternalReport={(selection: BackLinkListItem[]) =>
              handleBacklinkExternalReport(selection)
            }
            onSelectedBacklinksGoogleRescrape={(selection: BackLinkListItem[]) =>
              handleBacklinkGoogleRescrapeProcess(selection)
            }
            onBackButtonClick={() => handleBackButton()}
            refetch={() => refreshBacklinks()}
          >
            <AppPaginator
              pagination={pagination}
              onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
            ></AppPaginator>
          </AppBacklinksTable>
        </Flex>
      </DomainLayout>
    </BaseLayout>
  );
};

export default TestPage;
