import {
  AppConfirm,
  BackLink,
  BackLinkCreate,
  BackLinkListItem,
  BackLinkUpdateBulkRequest,
  BacklinkBulkCreateRequest,
  BacklinkBulkDeleteRequest,
  BacklinkBulkScrapeItem,
  BacklinkFilter,
  BacklinkFormData,
  BacklinkGoogleBulkRequest,
  BacklinkGoogleRequest,
  BacklinkReport,
  BacklinkTypes,
  ColumnDefinitionType,
  DomainViewModel,
  PageChangeEvent,
  PaginatedData,
  Pagination,
  ResponseMethodTypes,
  SearchParam,
  SortParam,
  sortingColumnsToSortingArray,
  stripTrailingSlash,
  useDialog,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import { selectUser } from '../store/slices/authentication.slice';
import { useAppSelector } from '../store/store';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSaveBacklinkReportMutation } from '../store/api/backlinkReportApi';
import {
  useEditBacklinkMutation,
  useBacklinkRescrapeMutation,
  useBacklinkBulkCreateMutation,
  useBacklinkLinkBulkDeleteMutation,
  useBacklinkBulkRescrapeMutation,
  useLazyFetchBacklinksByOptionsQuery,
  useBacklinkRescrapeGoogleMutation,
} from '../store/api/backlinksApi';
import { useLazyFetchDomainByIdQuery } from '../store/api/domainApi';
import {
  selectTempBacklinkList,
  selectTempBacklinkGoogleList,
  resetTempBacklinkGoogleList,
  resetTempBacklinkList,
  setSelectedBacklink,
} from '../store/slices/backlink.slice';
import { selectDomain, setSelectedDomain } from '../store/slices/domain.slice';
import { SettingsIcon } from '@chakra-ui/icons';
import { addDays } from 'date-fns';
import { BacklinkMenuItems } from '../components/backlinks/backlinks-table';
import ExternalReportForm from '../components/forms/external-report-form';
import { RoutesList } from '../router/router';
import { useToast } from '@chakra-ui/react';
import CheckOutForm from '../components/forms/checkout-form';
import {
  useEditOrganizationCreditByProductIdMutation,
  useLazyFetchOrganizationByIdQuery,
} from '../store/api/organizationApi';
import { useLazyFetchUserByIdQuery } from '../store/api/userApi';
import BacklinkGoogleReprocessForm from '../components/forms/backlink-google-reprocess';
import { selectOrganizationUsers } from '../store/slices/user.slice';

type backlinkPageReducerState = {
  currentPage: number;
  currentPageSize: number;
};

type backlinkPageReducerAction = {
  currentPage: number;
  currentPageSize: number;
};
const initialBacklinkPageState: backlinkPageReducerState = { currentPage: 1, currentPageSize: 10 };
const backlinkPageReducer = (
  state: backlinkPageReducerState,
  action: backlinkPageReducerAction
) => {
  return { currentPage: action.currentPage, currentPageSize: action.currentPageSize };
};

type useBacklinksProps = {
  filter?: BacklinkFilter;
};

const useBacklinks = ({ filter }: useBacklinksProps = {}) => {
  const { setLoading } = useLoading();
  const panel = usePanel();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const domain = useAppSelector(selectDomain);
  const tempBacklinks = useAppSelector(selectTempBacklinkList);
  const tempGoogleData = useAppSelector(selectTempBacklinkGoogleList);
  const [domainBacklinks, setDomainBacklinks] = useState<PaginatedData<BackLinkListItem>>();

  const [pageState, pageDispatch] = useReducer(backlinkPageReducer, initialBacklinkPageState);

  const [currentSortList, setCurrentSortList] = useState<SortParam[]>([]);
  const [currentSearchParam, setCurrentSearchParam] = useState<SearchParam>();
  const [backlinkFilter, setBacklinkFilter] = useState<BacklinkFilter | undefined>(filter);

  const [pagination, setPagination] = useState<Pagination>();
  const [editBacklinkTrigger] = useEditBacklinkMutation();

  const [getScrapedBacklinkData, { isLoading: isBacklinkScrapeLoading }] =
    useBacklinkRescrapeMutation();

  const [rescrapeBacklinkGoogleData, { isLoading: isBacklinkGoogleReScrapeLoading }] =
    useBacklinkRescrapeGoogleMutation();

  const [postBacklinkBulkCreate] = useBacklinkBulkCreateMutation();
  const [postBacklinkLinkBulkDelete] = useBacklinkLinkBulkDeleteMutation();
  const [editOrganizationCredits] = useEditOrganizationCreditByProductIdMutation();
  const [getUserById] = useLazyFetchUserByIdQuery();
  const [postBacklinkBulkUpdate] = useBacklinkBulkRescrapeMutation();
  const [getOrganization] = useLazyFetchOrganizationByIdQuery();
  const [getDomainById] = useLazyFetchDomainByIdQuery();
  const [saveBacklinkReportTrigger, { isLoading: isbacklinkReportAddSaving }] =
    useSaveBacklinkReportMutation();

  const [getBacklinksByDomain] = useLazyFetchBacklinksByOptionsQuery();

  const user = useAppSelector(selectUser);

  useEffect(() => {
    if (domainBacklinks && domainBacklinks.pagination) {
      setPagination(domainBacklinks?.pagination);

      // setCurrentPage(domainBacklinks.pagination?.page || 1);
      // setCurrentPageSize(domainBacklinks.pagination?.pageSize || 10);

      pageDispatch({
        currentPage: domainBacklinks.pagination?.page || 1,
        currentPageSize: domainBacklinks.pagination?.pageSize || 10,
      });
    }
  }, [domainBacklinks]);

  const actionSetupBacklinks = async (backlinkFilter: BacklinkFilter | undefined) => {
    setupBacklinks(
      pageState.currentPage,
      pageState.currentPageSize,
      currentSortList,
      currentSearchParam,
      backlinkFilter
    );
  };

  const setupBacklinks = async (
    page: number | undefined,
    pageSize: number | undefined,
    sortList: SortParam[] | undefined,
    searchParam: SearchParam | undefined,
    backlinkFilter: BacklinkFilter | undefined
  ) => {
    setLoading(true);
    try {
      const backlinks = await getBacklinksByDomain({
        domainId: domain?.id || '',
        pageNumber: page,
        pageSize,
        searchParam,
        sortParams: sortList,
        filterOptions: backlinkFilter,
      }).unwrap();

      setDomainBacklinks(backlinks);
    } catch (error) {
      console.log('Backlink set up error: ', error);
    }
    setLoading(false);
  };

  const addBacklink = async (
    backlinkFormsData: BacklinkFormData[],
    currentDomain: DomainViewModel,
    price?: number,
    batchId?: string
  ) => {
    try {
      if (!user) {
        toast({
          title: 'User account not found.',
          description: 'Sign out and back in.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
      }
      setLoading(true);

      const backlinkList: BackLink[] = [];

      const listToBeSaved = [];
      for (const backlinkFormData of backlinkFormsData) {
        if (backlinkFormData.url !== '' && backlinkFormData.url !== undefined) {
          // const strippedSlashUrl = stripTrailingSlash(backlinkFormData.url);

          // const foundBacklink = currentDomain?.backlinks?.find(
          //   (x) => stripTrailingSlash(x.url) === strippedSlashUrl
          // );

          // if (foundBacklink) {
          //   backlinkList.push(foundBacklink);
          // } else {
          const newBacklink: BackLinkCreate = {
            url: backlinkFormData.url,
            expectedLandingPage: backlinkFormData.expectedLandingPage,
            expectedAnchor: backlinkFormData.expectedAnchor,
            notes: backlinkFormData.notes,
            domainId: currentDomain?.id || '',
            expectedRelValue: backlinkFormData.expectedRel,
            backlinkType: BacklinkTypes.tierOne,
            price: backlinkFormData.price ? backlinkFormData.price.toString() : '',
            currency: backlinkFormData.priceCurrency || '',
            backlinkBatchId: batchId,
          };
          listToBeSaved.push(newBacklink);
          // }
        }
      }

      const bulkData: BacklinkBulkCreateRequest = {
        domainId: currentDomain?.id ?? '',
        backlinks: (listToBeSaved as BackLink[]) ?? [],
        backlinkBatchId: batchId ?? '',
        backlinkBatchPrice: price,
      };

      await postBacklinkBulkCreate(bulkData).unwrap();

      if (currentDomain) {
        const newDomain = await getDomainById(currentDomain.id).unwrap();
        await dispatch(setSelectedDomain(newDomain));
      }

      setLoading(false);

      if (backlinkList.length > 0) {
        handleDuplicateUrlAddingConfirm(backlinkList);
      } else {
        toast({
          title: `Backlink added`,
          description: `We've added. your backlink for you, come back soon to monitor the status`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        setupBacklinks(
          pageState.currentPage,
          pageState.currentPageSize,
          currentSortList,
          currentSearchParam,
          backlinkFilter
        );
      }
    } catch (error) {
      toast({
        title: `Backlink  add. error.=`,
        description: `We've run into a problem  adding. your backlink, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  const editBacklink = async (
    currentBacklink: BackLink,
    backlinkForm: BacklinkFormData,
    currentDomain: DomainViewModel,
    isNoteEdit = false,
    isPriceEdit = false
  ) => {
    try {
      if (!user) {
        toast({
          title: 'User account not found.',
          description: 'Sign out and back in.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
      }
      setLoading(true);

      // const backlinks = currentDomain?.backlinks;
      // const backlink = backlinks?.find((x) => x.id === backlinkForm.id);
      if (backlinkForm && backlinkForm.id && currentBacklink) {
        if (isNoteEdit) {
          await editBacklinkTrigger({
            ...currentBacklink,
            notes: backlinkForm.notes,
          }).unwrap();
        } else if (isPriceEdit) {
          await editBacklinkTrigger({
            ...currentBacklink,
            price: backlinkForm.price ? backlinkForm.price.toString() : '',
            currency: backlinkForm.priceCurrency || '',
          }).unwrap();
        } else {
          await editBacklinkTrigger({
            ...currentBacklink,
            url: backlinkForm.url,
            expectedLandingPage: backlinkForm.expectedLandingPage,
            expectedAnchor: backlinkForm.expectedAnchor,
            notes: backlinkForm.notes,
            expectedRelValue: backlinkForm.expectedRel,
            backlinkType: BacklinkTypes.tierOne,
            backlinkBatchId: currentBacklink.backlinkBatchId,
            price: backlinkForm.price ? backlinkForm.price.toString() : '',
            currency: backlinkForm.priceCurrency || '',
          }).unwrap();

          getScrapedBacklinkData({
            backlinkId: backlinkForm.id,
            parentUrl: currentDomain?.url || '',
          });
        }
      }

      if (currentDomain) {
        const newDomain = await getDomainById(currentDomain.id).unwrap();
        await dispatch(setSelectedDomain(newDomain));
      }

      setLoading(false);

      toast({
        title: `Backlink updated.`,
        description: `We've  updated. your backlink for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      setupBacklinks(
        pageState.currentPage,
        pageState.currentPageSize,
        currentSortList,
        currentSearchParam,
        backlinkFilter
      );
    } catch (error) {
      toast({
        title: `Backlink  update. error.=`,
        description: `We've run into a problem updating. your backlink, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  const handleBackButton = () => {
    navigate(RoutesList.Dashboard);
  };

  const handleBacklinkRescrape = async (backlinkId: string) => {
    setLoading(true);

    getScrapedBacklinkData({ backlinkId, parentUrl: domain?.url || '' });
    toast({
      title: 'We are processing your backlink',
      description: 'Your data will update soon!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    setLoading(false);
  };

  const handleBacklinkGoogleRescrape = async (data: BacklinkGoogleBulkRequest) => {
    setLoading(true);

    await rescrapeBacklinkGoogleData(data);
    toast({
      title: 'We are processing your backlink',
      description: 'Your data will update soon!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    setLoading(false);
  };

  // const handleBacklinkGoogleRescrapeProcess = (selectedBacklinks: BackLinkListItem[]) => {
  //   let credits = 2;
  //   if (user!.organization.availableCredit < credits) {
  //     openBuyCredits('Ooops, it seems you are out of credits! Purchase more to continue.');
  //   } else {
  //     dialog({
  //       title: 'Credit cost',
  //       size: 'lg',
  //       render: (onSubmit, onCancel) => {
  //         return (
  //           <AppConfirm
  //             message={`Refreshing the following link google data will cost ${credits} credits, if we find a false negative, we will refund your credits.`}
  //             onConfirm={async () => {
  //               onSubmit();
  //               handleBacklinkGoogleRescrape(backlinkId);
  //             }}
  //             onCancel={() => {
  //               onCancel();
  //             }}
  //           />
  //         );
  //       },
  //     });
  //   }
  // };

  const handleBacklinkGoogleRescrapeProcess = (selectedBacklinks: BackLinkListItem[]) => {
    dialog({
      title: 'Reprocess Google checks',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <BacklinkGoogleReprocessForm
            onSubmit={async (formData) => {
              let i = 0;
              if (formData.checkGoogleIndex) {
                i++;
              }
              const cost = selectedBacklinks.length * i;

              const mappedSelectedBacklinks: BacklinkGoogleRequest[] = selectedBacklinks.map(
                (x) => {
                  return {
                    backlinkId: x.id,
                    backlinkUrl: x.url,
                  };
                }
              );

              const data: BacklinkGoogleBulkRequest = {
                checkGoogleCache: formData.checkGoogleCache,
                checkGoogleIndex: formData.checkGoogleIndex,
                parentUrl: domain?.url ?? '',
                backlinks: mappedSelectedBacklinks,
              };
              await handleReprocessGoogleConfirm(cost, data);
            }}
          />
        );
      },
    });
  };

  const handleReprocessGoogleConfirm = (cost: number, data: BacklinkGoogleBulkRequest) => {
    dialog({
      title: 'Credit cost',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Refreshing the following link google data will cost ${cost} credits, if we find a false negative, we will refund your credits.`}
            onConfirm={async () => {
              setLoading(true);
              onSubmit();
              await handleBacklinkGoogleRescrape(data);
              setLoading(false);
            }}
            onCancel={() => onCancel()}
          />
        );
      },
    });
  };

  const upgradeOrganizationCreditByProduct = async (productId: string) => {
    await editOrganizationCredits({
      id: user?.organizationId,
      productId,
    });

    await getUserById(user?.id ?? '').unwrap();
    await getOrganization(user?.organizationId!).unwrap;
  };

  const openBuyCredits = async (title: string) => {
    panel({
      title: title,
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <CheckOutForm
            onSubmit={(item) => {
              upgradeOrganizationCreditByProduct(item.id);
            }}
            onCancelPlan={() => {}}
            onlyCreditProducts={true}
          />
        );
      },
    });
  };

  const handleBacklinksRescrape = async (selectedBacklinks: BackLinkListItem[]) => {
    let backlinks: BacklinkBulkScrapeItem[] = [];

    selectedBacklinks.forEach((x) => {
      if (x.backlinkMeta?.backlinkResponse?.methodType !== ResponseMethodTypes.ExternalApi) {
        backlinks.push({
          backlinkId: x.id,
          parentUrl: domain?.url,
          url: x.url,
          expectedLandingPage: x.expectedLandingPage,
          expectedAnchor: x.expectedAnchor,
          expectedRel: x.expectedRelValue,
          backlinkType: x.backlinkType,
          isManual: x.isManual,
        } as BacklinkBulkScrapeItem);
      }
    });
    const data: BackLinkUpdateBulkRequest = {
      backlinks: backlinks,
    };

    postBacklinkBulkUpdate(data);

    toast({
      title: 'We are processing your backlink',
      description: 'Your data will update soon!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const handleBacklinkExternalReport = async (selectedBacklinks: BackLinkListItem[]) => {
    if (!user) {
      toast({
        title: 'User account not found.',
        description: 'Sign out and back in.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      return;
    }

    const today = new Date();
    const backlinks = selectedBacklinks.map((x) => {
      return { backlink: x.id, isFixed: false };
    });

    return panel({
      title: 'Add External Report',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <ExternalReportForm
            onSubmit={async (formData) => {
              setLoading(true);

              const externalReport = {
                expiryDate: addDays(today, 7),
                backlinks: backlinks,
                userId: user?.id.toString() || '',
                emailAddress: formData.emailAddress,
                tagId: formData.tagId,
                notes: formData.notes,
                domainId: domain?.id || '',
              } as BacklinkReport;

              await saveBacklinkReportTrigger(externalReport);

              toast({
                title: 'External Report Created',
                description: "We've creted a report that will expire in 7 days",
                status: 'success',
                duration: 5000,
                isClosable: true,
              });

              setLoading(false);
              navigate(RoutesList.LinksReports);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDeleteBacklinkClicked = (selectedBacklinks: BackLinkListItem[]) => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selectedBacklinks.length} selected Backlinks?`}
            onConfirm={async () => {
              onSubmit();

              await handleDeleteBacklinks(selectedBacklinks);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDuplicateUrlAddingConfirm = (backlinks: BackLink[]) => {
    dialog({
      title: 'We found duplicate backlinks!',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Would you like us to reprocess ${backlinks.length} backlinks, this will allow us to update your backlink with the latest data & you will be able to see the differences when using the "New Link Differences" alert`}
            onConfirm={async () => {
              onSubmit();

              setLoading(true);

              const items = backlinks.map((x) => {
                return {
                  backlinkId: x.id,
                  parentUrl: domain?.url,
                  url: x.url,
                  expectedLandingPage: x.expectedLandingPage,
                  expectedAnchor: x.expectedAnchor,
                  expectedRel: x.expectedRelValue,
                  backlinkType: x.backlinkType,
                  isManual: x.isManual,
                } as BacklinkBulkScrapeItem;
              });
              const data: BackLinkUpdateBulkRequest = {
                backlinks: items,
              };

              postBacklinkBulkUpdate(data);

              // for (const backlink of backlinks) {
              //   getScrapedBacklinkData(backlink.id);
              // }

              setLoading(false);

              // setupBacklinks();
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDeleteBacklinks = async (backlinks: BackLink[]) => {
    try {
      setLoading(true);

      const backlinkUrls = backlinks.map((x) => x.id);

      const data = {
        backlinks: backlinkUrls,
      } as BacklinkBulkDeleteRequest;

      await postBacklinkLinkBulkDelete(data);
    } catch (e) {
      toast({
        title: 'Backlink Delete error.',
        description:
          "We've run into a problem deleting the selected backlinks, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }

    toast({
      title: 'Selected Backlinks deleted.',
      description: "We've deleted the backlinks you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    // setCurrentPage(1);
    pageDispatch({
      currentPage: 1,
      currentPageSize: pageState.currentPageSize,
    });

    if (domain) {
      const newDomain = await getDomainById(domain.id).unwrap();
      await dispatch(setSelectedDomain(newDomain));
    }

    setLoading(false);

    setupBacklinks(
      1,
      pageState.currentPageSize,
      currentSortList,
      currentSearchParam,
      backlinkFilter
    );
  };

  const handleBacklinkAction = async (row: BackLinkListItem) => {
    if (!row?.backlinkMeta?.linkStatus || !row?.backlinkGoogle?.lastGoogleCheckDate) {
      dialog({
        title: 'Still processing',
        render: (onSubmit, onCancel) => {
          return (
            <AppConfirm
              message={`We are still busy processing your backlink, please use this feature once the link has been processed.`}
              onConfirm={async () => {
                onSubmit();
              }}
              onCancel={() => {
                onCancel();
              }}
            ></AppConfirm>
          );
        },
      });
    } else {
      await dispatch(setSelectedBacklink(row));
      navigate(RoutesList.SecondTierLinks);
    }
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<BackLinkListItem>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setCurrentSortList(columnSortQueryString);

    setupBacklinks(
      pageState.currentPage,
      pageState.currentPageSize,
      columnSortQueryString,
      currentSearchParam,
      backlinkFilter
    );
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    // setCurrentPage(pageChangeEvent.currentPage);
    // setCurrentPageSize(pageChangeEvent.currentPageSize);

    pageDispatch({
      currentPage: pageChangeEvent.currentPage,
      currentPageSize: pageChangeEvent.currentPageSize,
    });

    setupBacklinks(
      pageChangeEvent.currentPage,
      pageChangeEvent.currentPageSize,
      currentSortList,
      currentSearchParam,
      backlinkFilter
    );
  };

  const handleSearchChange = (newSearchParam: SearchParam) => {
    setCurrentSearchParam(newSearchParam);
    setupBacklinks(
      pageState.currentPage,
      pageState.currentPageSize,
      currentSortList,
      newSearchParam,
      backlinkFilter
    );
  };

  const handleFiltersChange = (filter: BacklinkFilter) => {
    setBacklinkFilter(filter);
    setupBacklinks(
      pageState.currentPage,
      pageState.currentPageSize,
      currentSortList,
      currentSearchParam,
      filter
    );
  };

  const menuItems: BacklinkMenuItems[] = [
    {
      icon: <SettingsIcon />,
      onClick: (item) => handleBacklinkRescrape(item.id),
      label: 'Reprocess-link',
    },
    {
      icon: <SettingsIcon />,
      onClick: (item) => handleBacklinkGoogleRescrapeProcess([item]),
      label: 'Reprocess-Google-Checks',
    },
    // {
    //   icon: <BsLink />,
    //   onClick: (item) => handleBacklinkAction(item),
    //   label: 'T2 Backlinkit',
    // },
  ];

  const additionalBackLinkColumns: ColumnDefinitionType<BackLinkListItem>[] = [
    // {
    //   key: 'secondTierLinkCount',
    //   header: 'Backlinks',
    //   headerMeta: 'Tier 2 links nested under the tier 1 link.',
    //   headerSortable: false,
    //   render: (item) => <SecondTierLinkCountCell item={item} />,
    // },
  ];

  const refreshBacklinks = async () => {
    setLoading(true);
    dispatch(resetTempBacklinkList());
    dispatch(resetTempBacklinkGoogleList());
    await setupBacklinks(
      pageState.currentPage,
      pageState.currentPageSize,
      currentSortList,
      currentSearchParam,
      backlinkFilter
    );
    setLoading(false);
  };

  return {
    domain,
    domainBacklinks,
    tempBacklinks,
    tempGoogleData,
    additionalBackLinkColumns,
    menuItems,
    pageState,
    addBacklink,
    editBacklink,
    handleSearchChange,
    handleSortByColumnClick,
    handleFiltersChange,
    handleBacklinksRescrape,
    handleBacklinkGoogleRescrapeProcess,
    handleBackButton,
    refreshBacklinks,
    pagination,
    handleOnPageChange,
    handleBacklinkExternalReport,
    handleDeleteBacklinkClicked,
    handleDeleteBacklinks,
    setBacklinkFilter,
    actionSetupBacklinks,
  };
};

export default useBacklinks;
